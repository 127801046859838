const emailLeadStatDefaultState = {
    data: [],
    singleItem: {},
    loading: false,
    error: false,
    success: false,
    successMessage: '',
    uploadSuccess: '',
    errorMessage: null
};

const emailLeadStatReducer = (state = emailLeadStatDefaultState, action) => {
    switch (action.type) {
        case 'START_GET_EMAIL_LEAD_STAT':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'GET_EMAIL_LEAD_STAT_SUCCESS':
            return {
                ...state,
                singleItem: action.emailLeadStat,
                loading: false,
                error: false,
                errorMessage: null,
            };
        case 'START_GET_EMAIL_LEAD_STATS':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
                success: false
            };
        case 'GET_EMAIL_LEAD_STATS_SUCCESS':
            return {
                ...state,
                data: action.emailLeadStats,
                loading: false,
                error: false,
                errorMessage: null,
            };
        case 'CREATE_EMAIL_LEAD_STAT_SUCCESS':
            return {
                ...state,
                data: action.emailLeadStats,
                loading: false,
                uploadSuccess: true,
                error: false,
                errorMessage: null,
                successMessage: 'News Alert has been updated',
            };
        case 'START_STATS_EMAIL_UPDATE':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'UPDATE_STATS_EMAIL_SUCCESS':
            return {
                ...state,
                data: action.statsEmail,
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Microsite has been updated',
            };
        case 'START_REMOVE_STATS_EMAIL':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'REMOVE_STATS_EMAIL_SUCCESS':
            return {
                ...state,
                data: state.data.filter(ad => { // check original posts and only include posts that don't have that to delete ID
                    return !action.adIds.includes(ad.id); // includes is checking inside an array of numbers to see if any match
                }),
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Your ads have been removed!',
            };
        case 'STATS_EMAIL_CLEAR_SUCCESS':
            return {
                ...state,
                success: false
            };
        case 'SHOW_ERROR':
            return {
                ...state,
                loading: false,
                error: true,
                successMessage: '',
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};

export default emailLeadStatReducer;