import axiosInstance from '../../utility/axios';
import { POST_TYPE_NAME } from '../../utility/constants';

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});

export const startEmailLeadStat = () => ({
    type: 'START_GET_EMAIL_LEAD_STAT',
});

export const getEmailLeadStatSuccess = (formData) => ({
    type: 'GET_EMAIL_LEAD_STAT_SUCCESS',
    formData
});

export const getEmailLeadStat = (id) => {
    return (dispatch) => {
        dispatch(startEmailLeadStat());
        axiosInstance.get(`leads-csv-upload/${id}`)
            .then((response) => {
                if (response.data.formData === false) { // no post yet
                } else { // has post
                    console.log('response.data email content stat: ', response.data);
                    dispatch(getEmailLeadStatSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startGetEmailLeadStats = () => ({
    type: 'START_GET_EMAIL_LEAD_STATS',
});

export const getEmailLeadStatsSuccess = (emailLeadStats) => ({
    type: 'GET_EMAIL_LEAD_STATS_SUCCESS',
    emailLeadStats
});

// get all email content stats by emailStatId
export const getEmailLeadStats = (emailContentStatId, queryParams) => {
    return (dispatch) => {
        let url = `email-lead-stats/${emailContentStatId}`;
        if (queryParams) {
            url = `email-lead-stats/${emailContentStatId}/${queryParams}`;
        }
        console.log('get email content stats url: ', url);
        dispatch(startGetEmailLeadStats());
        axiosInstance.get(url)
            .then((response) => {
                dispatch(getEmailLeadStatsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startCreateEmailLeadStat = () => ({
    type: 'START_CREATE_EMAIL_LEAD_STAT'
});

export const createEmailLeadStatSuccess = (emailLeadStats) => ({
    type: 'CREATE_EMAIL_LEAD_STAT_SUCCESS',
    emailLeadStats
});

export const createEmailLeadStat = (formData, emailContentStatId) => {
    console.log('creating email lead stat', formData);
    return (dispatch) => {
        dispatch(startCreateEmailLeadStat());
        axiosInstance.post(`leads-csv-upload/${emailContentStatId}`, formData,)
            .then((response) => {
                dispatch(createEmailLeadStatSuccess(response.data.leads));
            })
            .catch((error) => {
                dispatch(showError());
                console.log(error);
            });
    }
};

export const startEmailLeadStatUpdate = () => ({
    type: 'START_EMAIL_LEAD_STAT_UPDATE',
});

export const updateJobSuccess = (formData) => ({
    type: 'UPDATE_EMAIL_LEAD_STAT_SUCCESS',
    formData
});

export const updateJob = (formData, emailLeadStatId) => {
    return (dispatch) => {
        dispatch(startEmailLeadStatUpdate());
        axiosInstance.put(`leads-csv-upload/${emailLeadStatId}`, formData)
            .then((response) => {
                dispatch(updateJobSuccess(formData));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startRemoveEmailLeadStat = () => ({
    type: 'START_REMOVE_EMAIL_LEAD_STAT'
});

export const removeEmailLeadStatSuccess = (emailLeadStatIds) => ({
    type: 'REMOVE_EMAIL_LEAD_STAT_SUCCESS',
    emailLeadStatIds
});

export const removeEmailLeadStat = (emailLeadStatIds) => {
    return (dispatch) => {
        const items = {
            itemsToDelete: emailLeadStatIds
        };
        dispatch(startRemoveEmailLeadStat());
        axiosInstance.delete('statsEmails', { data: items })
            .then((response) => {
                dispatch(removeEmailLeadStatSuccess(emailLeadStatIds));
                console.log('statsEmails removed!');
            })
            .catch((error) => {
                dispatch(showError());
                console.log(error);
            });
    }
};

