import React from 'react';

interface FileSelectorProps {
    onFileSelect: (file: File | null) => void;
}

const FileSelector: React.FC<FileSelectorProps> = ({ onFileSelect }) => {
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        onFileSelect(file);
    };

    return (
        <div className="file-selector">
            <input
                type="file"
                onChange={handleFileChange}
                accept="csv/*" // Customize file types if needed
            />
        </div>
    );
};

export default FileSelector;
